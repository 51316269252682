<template>
  <div :class="{ rtl: isLtr }" class="menu-page sm:p-1 mt-5 ra:mt-0">
    <div class="relative px-2 sm:px-0">
      <div
        style="top: 41%"
        class="respons hidden ra:block z-10 absolute mr-1 pr-0.5 h-20 w-20 border-8 border-gray-100 shadow-xl bg-rose rounded-full"
      >
        <img
          class="mr-2 mt-3"
          src="../assets/images/theme/logo-white.png"
          alt=""
        />
      </div>
      <div
        v-if="
          showHealth || showPatients || showEnviromental || showConfidential
        "
        style="top: 41%"
        class="respons hidden ra:block z-10 absolute mr-1 pr-0.5 h-20 w-20 border-8 border-gray-100 shadow-xl bg-lime-300 rounded-full"
      >
        <img
          class="mr-2 mt-3"
          src="../assets/images/theme/logo-white.png"
          alt=""
        />
      </div>
      <div class="flex flex-wrap justify-between">
        <!-- 1 -->
        <div
          class="widthBox bg-img-servis relative h-60 border border-gray-500"
        >
          <ListPatients
            @changeRoute="changeRoute('Reports')"
            :image="'list'"
            @HideList="showHealth = false"
            v-if="showHealth"
          />
          <div class="text-left w-11/12 mt-4 text-xl">
            <h3 class="ml-24">{{ $t('health') }}</h3>
            <h3 :class="{ 'ml-24': !isLtr, '-mt-2': !isLtr }" class="text-rose">
              {{ $t('services') }}
            </h3>
            <h3 v-if="!isLtr" class="ml-3 mt-0.5">کادر درمانی وخدمات</h3>
            <div
              @click="showHealth = true"
              class="cursor-pointer mr-auto pt-2 h-16 w-16 rounded-full bg-cyan-300 mt-8"
            >
              <div
                class="text-sm cursor-pointer flex flex-col items-center h-12 w-12 mr-2 p-2 font-bold rounded-full text-white border border-white"
              >
                <span :class="{ 'text-xl mt-0.5': isLtr }">{{
                  isRtl ? 'list' : 'liste'
                }}</span>
                <span v-if="!isLtr">لیست</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div
          class="widthBox mt-3 ra:mt-0 bg-img-Patients h-60 relative border border-gray-500"
        >
          <ListPatients
            @changeRoute="changeRoute('Profile')"
            :image="'patient-list'"
            @HideList="showPatients = false"
            v-if="showPatients"
          />
          <div class="text-right w-11/12 mt-12 pr-16 text-xl">
            <h3 class="text-rose">{{ $t('information') }}</h3>
            <h3 class="-mt-2 text-primary">{{ $t('patients') }}</h3>
            <h3 v-if="!isLtr" class="mt-1">شخص و سرپرست</h3>
            <div
              @click="showPatients = true"
              class="pt-2 h-16 w-16 rounded-full bg-purple-400 mt-8"
            >
              <div
                class="text-sm cursor-pointer flex flex-col items-center h-12 w-12 mr-2 p-2 font-bold rounded-full text-white border border-white"
              >
                <span :class="{ 'text-xl mt-0.5': isLtr }">{{
                  isRtl ? 'list' : 'liste'
                }}</span>
                <span v-if="!isLtr">لیست</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->

        <div
          class="bg-img-Enviromental widthBox relative h-60 border mt-3 border-gray-500"
        >
          <ListPatients
            @changeRoute="changeRoute('PatientEnvironment')"
            :class="{ ltr: isLtr }"
            :image="'list'"
            @HideList="showEnviromental = false"
            v-if="showEnviromental"
          />
          <div class="text-left w-11/12 mt-4 text-xl">
            <h3
              :class="{ 'ml-8': !isLtr, 'ml-20': isLtr }"
              class="text-lime-300"
            >
              {{ $t('enviromental') }}
            </h3>
            <h3 class="ml-12 -mt-2 text-rose">
              {{ $t('monitoring') }}
            </h3>
            <h3 v-if="!isLtr" class="ml-10 mt-1">باز بینی محیطی</h3>
            <div
              @click="showEnviromental = true"
              class="mr-auto ml-10 pt-2 h-16 w-16 rounded-full bg-purple-400 mt-8"
            >
              <div
                class="text-sm cursor-pointer flex flex-col items-center h-12 w-12 mr-2 p-2 font-bold rounded-full text-white border border-white"
              >
                <span :class="{ 'text-xl mt-0.5': isLtr }">{{
                  isRtl ? 'list' : 'liste'
                }}</span>
                <span v-if="!isLtr">لیست</span>
              </div>
            </div>
          </div>
        </div>
        <!--4 -->
        <div
          class="bg-img-Confidential widthBox relative h-60 mt-3 border border-gray-500"
        >
          <ListPatients
            @changeRoute="changeRoute('Information')"
            :image="'patient-list'"
            @HideList="showConfidential = false"
            v-if="showConfidential"
          />
          <div class="text-right w-11/12 mt-12 pr-16 text-xl">
            <h3 :class="{ 'text-rose': !isLtr, 'text-black': isLtr }">
              {{ $t('confidential') }}
            </h3>
            <h3 class="-mt-2 text-lime-300">
              {{ $t('health_status') }}
            </h3>
            <h3 v-if="!isLtr" class="mt-1">شخص و سرپرست</h3>
            <div
              @click="showConfidential = true"
              class="cursor-pointer pt-2 h-16 w-16 rounded-full bg-cyan-300 mt-8"
            >
              <div
                class="text-sm cursor-pointer flex flex-col items-center h-12 w-12 mr-2 p-2 font-bold rounded-full text-white border border-white"
              >
                <span :class="{ 'text-xl mt-0.5': isLtr }">{{
                  isRtl ? 'list' : 'liste'
                }}</span>
                <span v-if="!isLtr">لیست</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListPatients from '../components/MainPage/ListPatients.vue'
export default {
  components: { ListPatients },
  name: 'MainPage',
  data() {
    return {
      showHealth: false,
      showPatients: false,
      showEnviromental: false,
      showConfidential: false,
    }
  },

  methods: {
    changeRoute(route) {
      this.$router.push({ name: route })
    },
  },
}
</script>
<style scoped>
.respons {
  right: 46%;
}
.widthBox {
  width: 49.5%;
}
@media (max-width: 1000px) {
  .widthBox {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .respons {
    right: 45%;
  }
}
.bg-img-servis {
  background-image: url('../assets/images/theme/dockers-columns.png');
  background-size: 380px;
  background-repeat: no-repeat;
  background-position-x: 130px;
  background-position-y: 20px;
}
.bg-img-Patients {
  background-image: url('../assets/images/theme/phone-doctor.png');
  background-size: 230px;
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-position-y: 20px;
}
.bg-img-Enviromental {
  background-image: url('../assets/images/theme/monitoring.png');
  background-size: 230px;
  background-repeat: no-repeat;
  background-position-x: 250px;
  background-position-y: 20px;
}
.bg-img-Confidential {
  background-image: url('../assets/images/theme/confidentail.png');
  background-size: 230px;
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-position-y: 20px;
}
.menu-page {
  height: calc(
    100vh /* navbar */ - theme('spacing.16') /* plus image */ -
      theme('spacing.14') /* remove scroll */ - 3px
  ) !important;
}
</style>
