<template>
  <div
    :class="{ ltr: isLtr }"
    @click="$emit('HideList')"
    class="flex justify-center items-center h-60 w-full absolute bg-cyan-300 bg-opacity-80"
  >
    <div class="bg-white w-full sm:w-11/12 lg:w-3/4 h-4/5 rounded-lg">
      <div class="flex justify-around h-full">
        <div
          v-if="isRtl"
          class="bg-primary h-4/5 w-1 mt-3 ml-2 rounded-lg"
        ></div>
        <!--  -->
        <div :class="{ 'ml-16': isRtl, 'mr-10': isLtr }" class="mt-3">
          <div
            @click="$emit('changeRoute')"
            class="duration-500 group hover:text-rose cursor-pointer flex border-b-4 border-gray-300 border-dotted h-10"
          >
            <i
              class="duration-500 text-2xl group-hover:text-cyan-400 text-primary relative fas fa-heart"
            >
              <i
                class="text-white text-xs right-1 mr-0.5 top-2 absolute fas fa-plus"
              ></i>
              <div
                class="h-2 w-2 bg-red-400 rounded-full absolute top-5 left-0"
              ></div>
            </i>
            <h3 :class="{ 'mr-2': isRtl, 'ml-2': isLtr }" class="sm:mr-5 mt-1">
              {{ $t('name_main_page_list') }}
            </h3>
          </div>
          <div class="flex border-b-4 border-gray-300 border-dotted h-10">
            <i class="text-2xl text-primary relative fas fa-heart">
              <i
                class="text-white text-xs right-1 mr-0.5 top-2 absolute fas fa-plus"
              ></i>
              <div
                class="h-2 w-2 bg-red-400 rounded-full absolute top-5 left-0"
              ></div>
            </i>

            <h3 :class="{ 'mr-2': isRtl, 'ml-2': isLtr }" class="mr-5 mt-1">
              {{ $t('name2_main_page_list') }}
            </h3>
          </div>
          <div class="flex border-b-4 border-gray-300 border-dotted h-10">
            <i class="text-2xl text-primary relative fas fa-heart">
              <i
                class="text-white text-xs right-1 mr-0.5 top-2 absolute fas fa-plus"
              ></i>
              <div
                class="h-2 w-2 bg-red-400 rounded-full absolute top-5 left-0"
              ></div>
            </i>

            <h3 :class="{ 'mr-2': isRtl, 'ml-2': isLtr }" class="mr-5 mt-1">
              {{ $t('name3_main_page_list') }}
            </h3>
          </div>
          <div class="flex border-b-4 border-gray-300 border-dotted h-10">
            <i class="text-2xl text-primary relative fas fa-heart">
              <i
                class="text-white text-xs right-1 mr-0.5 top-2 absolute fas fa-plus"
              ></i>
              <div
                class="h-2 w-2 bg-red-400 rounded-full absolute top-5 left-0"
              ></div>
            </i>

            <h3 :class="{ 'mr-2': isRtl, 'ml-2': isLtr }" class="mr-5 mt-1">
              {{ $t('name4_main_page_list') }}
            </h3>
          </div>
        </div>
        <!--  -->
        <div
          v-if="isLtr"
          class="bg-primary h-4/5 w-1 mt-3 ml-2 rounded-lg"
        ></div>

        <!--  -->
        <div class="mt-3 ml-3 text-center">
          <span v-if="isLtr" class="font-bold">PATIENTS</span>
          <h3 v-if="!isLtr" class="font-bold">لیست بیماران</h3>
          <img
            width="150"
            class="mr-2"
            :src="require(`../../assets/images/theme/${image}.png`)"
          />
          <h3 v-if="!isLtr" class="font-black">PATIENT LIST</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListPatients',
  props: {
    image: {
      type: String,
    },
  },
}
</script>
<style></style>
